<template>
    <div class="wrap">
        <!-- <van-nav-bar title="检查预约" left-arrow @click-left="onClickLeft" /> -->

        <div class="btn dynamic_btn" @click="goHome">首页</div>
        <iframe :src="url" frameborder="0"></iframe>
        <!-- <div class="btn_1" @click="goDetail">线上预览</div> -->

    </div>
</template>
<script>
import { Base64 } from 'js-base64';

export default {
  data() {
    return {
        cardNo: "",
        url: "",
    };
  },
  created() {
    this.check()
  },
  methods: {
    check(){
        this.cardNo = Base64.encode(this.$route.query.cardNo);
        this.url = 'https://szjp301.zwjk.com/Appointment/CheckIndex?relation=' + this.cardNo;
    },
    goHome(){        
        this.$router.push({ path: "/" });
    }
  },
};
</script>
<style lang="less" scoped>
.wrap {
    min-height: 100vh;
    background: #F9FDFE;
    padding-bottom: 16px;

    .info {
        margin: 16px;
        background: #FFFFFF;
        box-shadow: 0px 5px 5px 1px rgba(239, 247, 246, 1);
        border-radius: 10px 10px 10px 10px;
        padding: 10px;

        .info_box {
            display: flex;
            justify-content: space-between;
            // align-items: center;
            margin-bottom: 10px;

            .box_1 {

                font-size: 15px;
                color: #333333;
            }

            .box_2 {


                color: #666666;
                font-size: 14px;
            }
        }

        .title {
            font-weight: bold;
            color: #1AB29B;
            font-size: 16px;

            .title_right {
                float: right;
                font-weight: bold;
                color: #333333;
                font-size: 14px;
            }
        }

        .content {
            margin-top: 12px;
            color: #666666;
            font-size: 14px;
        }

        .info_box2 {
            display: flex;
            margin-top: 12px;

            .box_1 {
                font-size: 15px;
                color: #333333;
            }

            .box_2 {
                color: #666666;
                font-size: 14px;
            }
        }
    }

    .btn {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        color: #fff;
        background: #1AB29B;
        border-radius: 23px 23px 23px 23px;
        margin: 16px 26px;
        position: fixed;
        bottom: 10vh;
        right: 10px;
    }

    .btn_1 {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        color: #1AB29B;
        border-radius: 23px 23px 23px 23px;
        margin: 0 26px;
        border: 1px solid #1AB29B;
    }
}


iframe {
    width: 100%;
    height: 100vh;
}

/deep/.van-hairline--top-bottom:after,
.van-hairline-unset--top-bottom:after {
    border-width: 0px 0;
}

/deep/.van-stepper {
    float: right;
}

/deep/.van-nav-bar__content {
    background-color: #1AB29B;
    color: #fff;
}

/deep/.van-nav-bar__title {
    color: #fff;
    font-size: 18px;
    max-width: 100%;
}

/deep/.van-icon-arrow-left {
    color: #fff;
}
</style>